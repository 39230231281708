/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./app/**/*.{js,ts,jsx,tsx,mdx}",
    "./pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./components/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: ['var(--font-roboto)'],
        mono: ['var(--font-roboto)'],
      },
      colors: {
        'maincolor': '#191919',
        'maincolortrans': '#2331424d',
        'secondary': '#0e2431',
        // 'third': '#AD916A',
        'third': '#D8B654',
        // 'third': '#D8B557',
        'forth': '#e3e3e3',
        'light': 'white',
        'mainhover': '#AD9243'

      },
      backgroundColor: ['hover'],
    },
  },
  plugins: [],
}
